import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import { Container } from "@material-ui/core"
import { Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core/styles";
//Models
import { PromotionalTermModel } from "../../components/utils/model/PromotionalTermModel";
//Assets
import GANAMAS24 from "./../../assets/Benefits/GANAMAS_24.png";

const useStyles = makeStyles(theme => ({
    root: {},
    ol: { paddingInlineStart: 0 },
    containerPart: {
        [theme.breakpoints.down("md")]: {
            marginTop: "10em",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "7em",
        },
        marginTop: "12em",
    },
    imgSize: {
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xl")]: {
            width: "100%",
        },
        width: "100%",
    },
    boxImg: {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
    },
    liStyle: {
        textAlign: "justify",
        padding: 3,
    },
    scrollableDiv: {
        [theme.breakpoints.down("xs")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("md")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("lg")]: {
            height: "700px", /* Alto del div */
        },
        [theme.breakpoints.down("xl")]: {
            height: "800px", /* Alto del div */
        },
        height: "auto", /* Alto del div */
        width: "auto", /* Ancho del div */
        overflow: "auto", /* Habilita scroll cuando el contenido excede las dimensiones */
        '&::-webkit-scrollbar': {
            width: '10px', // Ancho del scrollbar
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Color del track
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color del scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Color del scrollbar al hover
        },
    }
}));

export default function Benefits() {
    let propertiesLayout = new PromotionalTermModel("Ganamas24", GANAMAS24, "GANAMAS24");
    const classes = useStyles()
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{propertiesLayout.name.toUpperCase()} | Terminos & Condiciones</title>
            </Helmet>
            <Container className={classes.containerPart}>
                <Box mt={4} py={2}>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>Términos y Condiciones de la Campaña Promocional</b>
                    </Typography>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>“{propertiesLayout.name.toUpperCase()}”</b>
                    </Typography>
                </Box>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 6, md: 12 }} sx={{
                    maxHeight: {
                        xs: "auto", // Pantallas pequeñas
                        sm: "auto", // Pantallas medianas
                        md: "700px", // Pantallas grandes
                        lg: "800px", // Pantallas más grandes
                    }, marginBottom: 10
                }}>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        {" "}
                        <img
                            src={propertiesLayout.linkImage}
                            alt={propertiesLayout.desc}
                            className={classes.imgSize}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        <div className={classes.scrollableDiv}>
                            <Typography variant="h4" gutterBottom inline>
                                <b>1. Vigencia de la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                La campaña “GANAMAS24” estará vigente desde el 1 de noviembre de 2024
                                hasta el 31 de diciembre de 2024.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>2. Requisitos para participar</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder al beneficio de esta campaña, los clientes deben cumplir con los
                                siguientes requisitos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Clientes elegibles:</b> Clientes activos o con cuentas de reciente apertura.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Abono de capital:</b> Realizar un abono mínimo de $5,000 MXN en el
                                        producto &quot;Acceso&quot; durante el periodo de vigencia de la campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>3. Incentivo</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Al participar en esta campaña, el cliente podrá incrementar la tasa base del
                                producto “Acceso” (2.5%) en un 10.5%, alcanzando así una tasa final máxima del
                                13% anualizado. Para obtener este incremento, el cliente deberá realizar la
                                contratación del producto “Acceso” por un importe igual o superior a $5,000 MXN.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>4. Condiciones para obtener el beneficio de la campaña:</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder al beneficio de la campaña, el cliente debe realizar la contratación
                                del producto “Acceso” por un monto mínimo de $5,000 MXN.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>5. Procedimiento para Acceder a la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para beneficiarse de esta campaña, los clientes deben seguir estos pasos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Paso 1:</b> Transferir fondos por un importe igual o superior a $5,000 MXN a
                                        su Cuenta Cero CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 2:</b> Realizar la contratación del producto “Acceso” por un importe igual
                                        o superior a $5,000 MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 3:</b> El cliente deberá solicitar a su Asesor Financiero la aplicación del
                                        beneficio de la presente campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>6. Condiciones Generales</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Producto aplicable: </b>Esta promoción se aplica únicamente para el producto <b>“Acceso”</b>.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Capital nuevo:</b> El capital debe ser nuevo y transferido entre el 1 de
                                        noviembre de 2024 y el 31 de diciembre de 2024.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Sin excepciones:</b> No se permitirán contrataciones fuera del plazo de
                                        vigencia.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Tasa sujeta a cambios:</b> La tasa de rendimiento puede cambiar sin aviso
                                        previo según las condiciones del mercado.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Clientes con inversión “Acceso” activa:</b> En caso de querer aplicar este
                                        beneficio a su inversión actual en acceso, el cliente deberá incrementar el
                                        20% sobre el saldo actual invertido en dicho producto.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Exclusividad de la Campaña:</b> Los fondos ingresados ​​bajo esta promoción
                                        solo se aplican a los términos de esta campaña. Si el cliente desea
                                        participar en otra campaña, deberá cumplir con los términos específicos de
                                        esa campaña adicional.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Vigencia de la Tasa:</b> La tasa de interés promocional será vigente hasta el
                                        15 de Enero de 2025, con independencia de la fecha en la que haya
                                        adquirido el beneficio de esta campaña el cliente.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Plazo de aplicación del beneficio:</b> El beneficio se reflejará en el producto
                                        Acceso en un plazo máximo de 72 horas. Los clientes podrán confirmar el
                                        incremento en su tasa de interés a través de su aplicación móvil o sitio web.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Aceptación de los Términos y Condiciones:</b> Al participar en esta
                                        campaña, los clientes aceptan y se comprometen a cumplir con todos los
                                        términos y condiciones aquí establecidos.
                                    </li>

                                </ol>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}
